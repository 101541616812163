/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import "office-ui-fabric-react/dist/css/fabric.min.css";
//import './styles.less';
import * as React from "react";
import * as ReactDOM from "react-dom";
//import { useContext } from 'react';
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import App from "./components/AppDialogFn";
import { DialogType, LimaTitleJudge } from "./limaDialogCommon";
import limaDialogStore from "../store/limaDialogStore";
import { judgeActParaItemOK } from "../api/schema/getJudgeActParaResp";
import { judgeData } from "../types/keywords";
import {
  DIALOGMESSAGETYPE_ENUM,
  DialogParentMessage,
  DIALOGSTATETYPE_ENUM,
  DIALOGTYPE_ENUM,
} from "../types/dialogData";

initializeIcons();

let isOfficeInitialized = false;
let platform: Office.PlatformType | null = null;

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Component title={LimaTitleJudge} isOfficeInitialized={isOfficeInitialized} dialogType={DialogType.judge} />
    </AppContainer>,
    document.getElementById("container")
  );
};

render(App);

if ((module as any).hot) {
  (module as any).hot.accept("./components/AppDialogFn", () => {
    const NextApp = require("./components/AppDialogFn").default;
    render(NextApp);
  });
}

void Office.onReady((info) => {
  // If needed, Office.js is ready to be called
  //    log.info(tag.dialogJudge, "office onReady");

  (window as any).Promise = OfficeExtension.Promise;

  platform = info.platform;
  if (info.host === Office.HostType.Excel) {
    // Do Excel-specific initialization (for example, make add-in task pane's
    // appearance compatible with Excel "green").
  }
  if (info.platform === Office.PlatformType.PC) {
    // Make minor layout changes in the task pane.
  }
  console.log(`Office.js is now ready in ${info.host} on ${info.platform}`);
  isOfficeInitialized = true;

  render(App);
}).then(function () {
  console.log(`onReady: register handler on data recived`);
  Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, onMessageFromParent);
  console.log(`onReady: ask parent to get data`);
  if (platform == Office.PlatformType.OfficeOnline) {
    window.opener.postMessage(JSON.stringify(requestData()), "*");
  } else {
    Office.context.ui.messageParent(JSON.stringify(requestData()));
  }
  console.log(`onReady: finish`);
});

function requestData() {
  const request: DialogParentMessage = {
    dialogType: DIALOGTYPE_ENUM.JUDGE,
    messageData: DIALOGSTATETYPE_ENUM.DATA_REQUESTED,
    messageType: DIALOGMESSAGETYPE_ENUM.STATE,
  };
  return request;
}

function onMessageFromParent(arg) {
  console.log("message", JSON.parse(arg.message));
  limaDialogStore.setDataJSON(JSON.parse(arg.message));

  const messageFromParent: judgeActParaItemOK | judgeData = JSON.parse(arg.message);
  limaDialogStore.setJudge(messageFromParent);

  limaDialogStore.isLoading && limaDialogStore.setIsLoading(false);
}
